import './index.css';
import './App.css';
import Tilt from 'react-parallax-tilt';
import data from './Front.mp4';
import video from './video.mp4';
import Accordion from './accordion';


function App() {
  return (
    <div className='misc'>
      {/* <div className='header'>
          <div></div>
          <div>
          <span className='header_right'>About</span>
          <span className='header_right'>How it works</span>
          <span className='header_right'>Why Nataraj247</span>
          <span className='header_right'>FAQ</span>
          <span className='header_right'>Whatsapp Us</span>
          </div>
      </div> */}

      <nav className="navbar">
        <div className="navbar-container container">
          <input type="checkbox" name="" id="" />
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>
          <ul className="menu-items">
            <li><a href="#about">About</a></li>
            <li><a href="#how_it_works">How it works</a></li>
            <li><a href="#why_nataraj">Why Nataraj247</a></li>
            <li><a href="#FAQ">FAQ</a></li>
            <li><a href="#about_whatsapp">Whatsapp Us</a></li>
          </ul>
          {/* <h1 className="logo">Natraj247</h1> */}
          <img src="/nataraj_logo_new.png" alt="" className="logo"/>
        </div>
      </nav>

      <div className='about'>
        {/* <video src={data} autoPlay={true} /> className='front_video'*/}
        <div style={{ width: '100%', height: '100%' }}>
          <video autoPlay muted loop preload="none" style={{ width: '100%', height: '100%' }}>
            <source src="/video/front_latest.mp4" type="video/mp4" />
          </video>
        </div>
        <h1 className='mt-5' id='about'>Nataraj 247</h1>
        <h1 className='mt-3 px-2'>India's Fastest Growing Online Cricket ID Provider</h1>
        <div className='about_mid'>
          <p id='about_mid_text'>
            Welcome to Nataraj 247, Most trusted online cricket id provider in India<br></br>
          </p>
          <p id='about_mid_text'>
            Nataraj 247 gives their users the opportunity to earn various Cashback. With us You can create your Online Cricket Id and Enjoy a login Cashback of 10% not only Cashback it also provide several other Offers and Promotion as well. Nataraj 247 is the Most Trusted Site for Online Cricket Id. It offers a wide range of Cricket Betting Id Options . Nataraj 247 is a reliable and Trustworthy Online Cricket Id Provider in India because it offers Secure Payment options. And Customer Support are 24*7 available.
          </p>
          <p id='about_mid_text'>
            Nataraj 247 offers the Best online Cricket ID Get your Online Cricket Id with Instant withdrawal in 1 minutes.
          </p>
        </div>
        <a href="https://wa.me/+919144466650" target="_blank" rel="noreferrer noopener" className='about_whatsapp'>Whastapp Us</a>
      </div>

      <div className='how_it_works' id='how_it_works'>
        <h1>How It Works</h1>
        <div className='how_it_works_last'>
          <h2>Get Your Online Cricket Id Now</h2>
          <p id='about_whatsapp'>If you're interested in creating an online cricket ID, I recommend you to see the best platforms and websites that offer online cricket games and leagues to find one that best fits your needs and preferences. This all you can find in One & Only Nataraj 247.</p>
          <a href="https://wa.me/+919144466650" target="_blank" rel="noreferrer noopener" className='about_whatsapp'>Whastapp Us</a>
          {/* <a href='google.com'>google</a> */}
        </div>
      </div>

      <div className='why_nataraj' id='why_nataraj'>
        <h1 className='px-2'>Why Nataraj 247 For Online ID's</h1>
        <div className='why_nataraj_cards'>
          <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} perspective={1000} tiltReverse={true}>
            <div className='test_tilt'>
              <h3>ONLINE ID</h3>
              <p className='mt-5'>Get your own online ID with us with just a simple click, and get started!</p>
            </div>
          </Tilt>
          <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} perspective={1000} tiltReverse={true}>
            <div className='test_tilt'>
              <h3>24*7 SUPPORT</h3>
              <p className='mt-5'>Someone will always be up and working to address any issues/problems you may have, because your experience is always put first.</p>
            </div>
          </Tilt>
          <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} perspective={1000} tiltReverse={true}>
            <div className='test_tilt'>
              <h3>100% TRUSTED SERVICES</h3>
              <p className='mt-4' >Nataraj 247 encourages you to gamble responsibly and we guarantee that it is 100% legal. We are India’s one of the trusted gambling websites.</p>
            </div>
          </Tilt>
          <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} perspective={1000} tiltReverse={true}>
            <div className='test_tilt'>
              <h3>INSTANT WITHDRAWAL & REFILL</h3>
              <p className='mt-3'>Nataraj 247 provides prompt service of id withdrawal & refilling along with maximum and minimum amount.</p>
            </div>
          </Tilt>
          <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} perspective={1000} tiltReverse={true}>
            <div className='test_tilt'>
              <h3>AVAILABLE PAYMENT METHODS</h3>
              <p className='mt-4'>Access multiple payment methods like PAYTM, BANK TRANSFER , UPI, PHONEPE, GPAY</p>
            </div>
          </Tilt>
          <Tilt tiltMaxAngleX={10} tiltMaxAngleY={10} perspective={1000} tiltReverse={true}>
            <div className='test_tilt'>
              <h3>HIGH SECURITY</h3>
              <p className='mt-3'>Your safety and trust is our top priority which is why Nataraj 247 makes sure your information is safe and never shared.</p>
            </div>
          </Tilt>
        </div>
      </div>

      <div className='FAQ' id='FAQ'>
        <h1 className='mt-5 text-center px-2'>How Can We Help You ?</h1>
        <h2 className='text-center mt-3'>Popular Questions</h2>

        <div className='accordion-margin'>
          <div className="accordion accordion-flush" id="accordionFlushExample">


            <div className="accordion-item bg-secondary border-0">
              <h2 className="accordion-header accordion-bg-color">
                <button className="accordion-button collapsed accordion-bg-color" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="false" aria-controls="flush-collapseOne">
                  Why Nataraj 247
                </button>
              </h2>
              <div id="faq1" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  We have record of providing best and fastest customer service in the industry with great satisfaction.
                </div>
              </div>
            </div>

            <div className="accordion-item bg-secondary border-0">
              <h2 className="accordion-header accordion-bg-color">
                <button className="accordion-button collapsed accordion-bg-color" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="false" aria-controls="flush-collapseOne">
                  How to create account
                </button>
              </h2>
              <div id="faq2" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  Reach out to us by clicking on "Whatsapp Us" button above.
                </div>
              </div>
            </div>

            {/* <div className="accordion-item bg-secondary border-0">
              <h2 className="accordion-header accordion-bg-color">
                <button className="accordion-button collapsed accordion-bg-color" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="flush-collapseOne">
                  Question 3
                </button>
              </h2>
              <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  lorem ipsum
                </div>
              </div>
            </div> */}

          </div>
        </div>


      </div>

      <div className='footer'>
        <p>customersupport@nataraj247.com</p>
        {/* <p id='about'>About</p> */}
        <p><a href="#about" className='text-decoration-none text-white'>About</a></p>
        <p>Blog</p>
        <p>More</p>
      </div>

      <div className='copyright'>
        copyright ©
      </div>

    </div>
  );
}

export default App;
